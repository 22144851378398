// import axios from 'axios'
// import {useState} from 'react'

const Contact = () => {
    // const [email, setEmail] = useState('')
    // const [name, setName] = useState('')
    // const [message, setMessage] = useState('')
    // const [btn, setBtn] = useState(true)
    // const [nameNotif, setNameNotif] = useState('')
    // const [emailNotif, setEmailNotif] = useState('')
    // const [msgNotif, setMsgNotif] = useState('')
    // const [submitted, setSubmitted] = useState(false)

    // const submitHandler = (e) => {
    //     e.preventDefault()
    //     console.log('you submitted')

    //     axios.post('/send', {email, name, message})
    //         .then(res => {
    //             console.log(res.data)
    //             setSubmitted(true)
    //         })
    // }

    // const btnCheck = () => {
    //     if (email && name && message) {
    //         setBtn(false)
    //     }
    // }

    // const nameHandler = e => {
    //     let {value} = e.target
    //     value = value.trim()

    //     if (value.length < 1) {
    //         setNameNotif('You must enter a name.')
    //     } else {
    //         setNameNotif('')
    //         setName(value)
    //         btnCheck()
    //     }
    // }

    // const emailHandler = e => {
    //     let {value} = e.target
    //     value.trim()

    //     if (!value.includes('@') || !value.includes('.')) {
    //         setEmailNotif('You must enter a valid email address.')
    //     } else {
    //         setEmailNotif('')
    //         setEmail(value)
    //         btnCheck()
    //     }
    // }

    // const messageHandler = e => {
    //     let {value} = e.target
    //     value.trim()

    //     if (value.length < 5) {
    //         setMsgNotif('You must include a message.')
    //     } else {
    //         setMsgNotif('')
    //         setMessage(value)
    //         btnCheck()
    //     }
    // }

    return (
        <div className='contact-container'>
            <div className='contact' id='contact'>
                <h2>Contact</h2>
                <form method='post' data-netlify='true' name='contact' action='/success'>
                        <input type="hidden" name="form-name" value="contact" />
                        <div id='input-container'>
                            <input type='text' name='name' placeholder='Your Name'/>
                            <input type='text' name='email' placeholder='Your Email Address'/>
                        </div>
                        <textarea type='text' name='message' placeholder='Type Your Message Here'/>
                        <button className='btn' type='submit'>Send</button>
                    </form>
            </div>
        </div>
    )
}

export default Contact