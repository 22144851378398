import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import logo from '../assets/logo.png'
// import smallLogo from '../assets/smallLogo.png'

const Header = () => {
    const [menu, setMenu] = useState(false)
    const [mobile, setMobile] = useState(true)
    
    const setDisplay = () => {
        if (window.innerWidth < 700) {
            setMenu(false)
            setMobile(true)
        } else {
            setMenu(true)
            setMobile(false)
        }
    }

    useEffect(() => {
       window.addEventListener('resize', setDisplay)
       setDisplay()
    }, [mobile])

    return (
        <div className='header-container'>
            <header className='header'>
                <Link to='/' onClick={() => {if (mobile) setMenu(!menu)}}><img src={logo} alt='infinite rainbow logo'/></Link>

                {mobile && <p onClick={() => setMenu(!menu)}>&#9776;</p>}

                <nav className={menu ? 'open' : 'closed'} id='nav'>
                    <Link to='/' onClick={() => {if (mobile) setMenu(!menu)}}>Home</Link>
                    <Link to='/contact' onClick={() => {if (mobile) setMenu(!menu)}}>Contact</Link>
                    {/* <Link to='/team' onClick={() => {if (mobile) setMenu(!menu)}}>
                        Team
                    </Link> */}
                    {/* <a
                        href='https://joxhiyeibg67pzv2-59193950370.shopifypreview.com/'
                        target='_blank'
                        rel='noreferrer'>
                        Store</a> */}
                </nav>
            </header>
        </div>
    )
}

export default Header