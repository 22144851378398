import insta from '../assets/instagram.svg'

const Footer = () => {
    return (
        <div className='footer-container'>
            <footer className='footer'>
                <h3>Infinite Rainbow</h3>

                <div>
                    <a 
                        href='https://www.instagram.com/energydrinksforkids/'
                        target='_blank'
                        rel='noreferrer'>
                        <img src={insta} alt='instagram logo'/>
                    </a>
                    <a
                        href='https://energy-drinks-for-kids.myshopify.com/'
                        target='_blank'
                        rel='noreferrer'>
                        Store</a>
                </div>
            </footer>
        </div>
    )
}

export default Footer