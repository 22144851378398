const Mission = () => {
    return (
        <div className='text-container'>
            <div className='text' id='mission'>
                <h2>Our Mission Statement</h2>
                <p><span>As</span> a company that makes energy drinks for kids, we’ve been fielding tough questions since our inception: What are energy drinks for kids? (Just water, but don’t tell the kids!). Are these drinks safe? (Not only is water safe, you’ll die without it!). And finally, aside from energizing kids with clean energy, what does Infinite Rainbow do for kids? The Infinite Rainbow Foundation For Kids is IR’s answer to this last question. The Infinite Rainbow Foundation For Kids is many things, but our simplest and clearest objective is to help kids who have a lot of energy, but not a lot of resources.</p>
                <p><span>People</span> have accused us of sending the wrong message to kids, but the only message we’ve ever tried to express to kids is “you CAN fly!” Our foundation directs a portion of Infinite Rainbow’s profits to kids who want to begin a career in aviation or who aspire to participate in air sports in whatever form. In the mold of the Freefall Foundation, we offer funding for first tandems, tunnel coaching, and air sports training for kids up to 19 (you might say we’re like a Freefall Foundation For Kids). In addition, for kids who don’t do energy good when it comes to education, we’ve directed a portion of our profits to fund after-school tutoring, extracurricular activities, and higher education for underserved kids. For more information about the Infinite Rainbow Foundation For Kids or to nominate a kid for support, please reach out to <a href="mailto: gthall@ucsd.edu">gthall@ucsd.edu</a>.</p>
            </div>
        </div>
    )
}

export default Mission