import ImageGallery from 'react-image-gallery'
import one from '../assets/one_ir.jpg'
import two from '../assets/two_ir.jpg'
import three from '../assets/three_ir.jpeg'
import four from '../assets/four_ir.jpg'
import five from '../assets/five_ir.png'
import six from '../assets/six_ir.jpg'
import ten from '../assets/ten_ir.jpg'

// import energyDrink from '../assets/energyDrink.png'
// import blackShirt from '../assets/blackShirt.jpeg'
// import blueShirt from '../assets/blueShirt.jpeg'
// import greyShirt from '../assets/greyShirt.jpeg'
// import whiteShirt from '../assets/whiteShirt.jpeg'

const photos = [
    {
        original: one, 
        originalAlt: 'infinite rainbow energy drink', 
        sizes: '200px'
    }, 
    {
        original: two, 
        originalAlt: 'black infinite rainbow shirt', 
        sizes: '200px'
    }, 
    {
        original: three, 
        originalAlt: 'blue infinite rainbow shirt', 
        sizes: '200px'
    }, 
    {
        original: four, 
        originalAlt: 'grey infinite rainbow shirt', 
        sizes: '200px'
    }, 
    {
        original: five, 
        originalAlt: 'white infinite rainbow shirt', 
        sizes: '200px'
    }, 
    {
        original: six, 
        originalAlt: 'white infinite rainbow shirt', 
        sizes: '200px'
    }, 
    {
        original: ten, 
        originalAlt: 'white infinite rainbow shirt', 
        sizes: '200px'
    }
]
// const photos = [
//     {
//         original: energyDrink, 
//         originalAlt: 'infinite rainbow energy drink', 
//         sizes: '200px'
//     }, 
//     {
//         original: blackShirt, 
//         originalAlt: 'black infinite rainbow shirt', 
//         sizes: '200px'
//     }, 
//     {
//         original: blueShirt, 
//         originalAlt: 'blue infinite rainbow shirt', 
//         sizes: '200px'
//     }, 
//     {
//         original: greyShirt, 
//         originalAlt: 'grey infinite rainbow shirt', 
//         sizes: '200px'
//     }, 
//     {
//         original: whiteShirt, 
//         originalAlt: 'white infinite rainbow shirt', 
//         sizes: '200px'
//     }
// ]

const Hero = () => {
    return (
        <div className='hero'>
            <ImageGallery 
                items={photos} 
                id='gallery'
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay={true}/>
        </div>
    )
}

export default Hero