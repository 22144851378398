import './reset.css'
import './styles/mobile.scss'
import './styles/mid.scss'
import './styles/thousand.scss'
import Header from './Components/Header'
import Hero from './Components/Hero'
import Mission from './Components/Mission'
import Contact from './Components/Contact'
import Success from './Components/Success'
import Footer from './Components/Footer'
import {Element} from 'react-scroll'
import {Switch, Route} from 'react-router-dom'

const App = () => {
  return (
    <div className='app'>
      <Element to='top'>
        <Header/> 
      </Element>

      <Switch>
        <Route exact path='/'>
          <Hero/>
          <Mission/>
        </Route>

        <Route path='/contact' component={Contact}/>
        <Route path='/success' component={Success}/>
      </Switch>

      


      <Footer/>
    </div>
  )
}

export default App